/**
* news-list, news-item
* -----------------------------------------------------------------------------
*/
.news {
  @border:                       1px solid @gray--darken; // рамка элементов
  @border-radius:                0; // скругление углон у некоторых блоков
  @gutter-between-elements:      10px;
  @element-margin-bottom:        20px; // общий нижний отступ у элементов на странице
  @block-margin-bottom:          40px; // отступ у целого блока

  //== иконки
  @icon-color:                   #424141;
  @icon-background-color-hover:  #e6e6e6;

  //== теги новостей
  @news-label-color:             #fff;
  @news-label-background-color:  #1b7eb0;



  .news-border {
    border: @border;
    border-radius: @border-radius;
  }



  &-label {
    display: inline-block;
    vertical-align: top;
    padding: 2px 8px;
    text-decoration: none;
    background-color: @news-label-background-color;
    color: @news-label-color;
    border-radius: @border-radius;
  }

  /**
  * универсальные стили для обоих страниц новостей
  */
  &-item-info {
    display: flex;
    margin-left: -@gutter-between-elements;
    margin-right: -@gutter-between-elements;
    margin-bottom: 12px;
  }

  &-item-info__item {
    padding-left: @gutter-between-elements;
    padding-right: @gutter-between-elements;
    font-size: 0.9rem;
  }

  &-item-tag-list {
    display: flex;
    margin-left: -@gutter-between-elements;
    margin-right: -@gutter-between-elements;
    margin-bottom: @element-margin-bottom;
  }

  &-item-tag-item {
    padding-left: @gutter-between-elements;
    padding-right: @gutter-between-elements;
  }

  &-item-text-info {
    color: @text-color;
  }

  &-social-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -@gutter-between-elements / 2;
    margin-right: -@gutter-between-elements / 2;
  }

  &-social-item {
    padding-left: @gutter-between-elements / 2;
    padding-right: @gutter-between-elements / 2;
  }

  &-social-item__link {
    display: block;
    width: 100%;
    min-width: 35px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: @gutter-between-elements;
    text-align: center;
    border-color: @icon-color;
    border-width: 1px;
    color: @icon-color;
    transition: 0.15s background-color ease-in;

    &:hover {
      background-color: @icon-background-color-hover;
    }
  }

  &-newsletter-form-wrapper {
    margin-bottom: @element-margin-bottom * 2;

    & .btn[type="submit"] {
      width: 100%;
    }
  }

  &-newsletter-form {
    padding-top: 20px;

    .news-border;
  }

  &-newsletter-form .modal-form-row--checkbox {
    margin-bottom: @element-margin-bottom;
  }

  &-newsletter-form .modal-form-row--checkbox label.error {
    display: none !important; /* нет места под сообщение */
  }

  &-item-link-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &-slider-wrapper {
    position: relative;
    margin-bottom: @block-margin-bottom;

    // .news-border;
  }

  &-slider-button-prev {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 0;
    border: 0;
    background-color: transparent;

    &:focus {outline:0}
    &:hover {
      & svg {
        fill: lighten(@primary-color, 10%);
      }
    }

    & svg {
      fill: @primary-color;
      transform: rotate(90deg);
    }
  }

  &-slider-button-next {
    position: absolute;
    z-index: 100;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 0;
    border: 0;
    background-color: transparent;

    &:focus {outline:0}
    &:hover {
      & svg {
        fill: lighten(@primary-color, 10%);
      }
    }

    & svg {
      fill: @primary-color;
      transform: rotate(-90deg);
    }
  }

  &-slider-list {
    margin-bottom: @element-margin-bottom;
    height: 500px;
  }

  &-slider-social-action-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &-slider-item {
    position: relative;
    height: 500px !important;
  }

  &-slider-item__image {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &-slider-item-text-wrapper {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  &-slider-item-text-wrapper__head {
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    text-shadow: 0 0 4px #000;
    font-size: 28px;
    line-height: 1.1;
  }

  &-slider-item-text-wrapper__subhead {
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    text-shadow: 0 0 4px #000;
  }

  &-slider-social-vote-list {
    display: flex;
    margin-left: -@gutter-between-elements;
    margin-right: -@gutter-between-elements;
  }

  &-slider-social-vote-item {
    padding-left: @gutter-between-elements;
    padding-right: @gutter-between-elements;
  }

  &-slider-social-vote-item__link {
    display: flex;
    // margin-left: -@gutter-between-elements;
    // margin-right: -@gutter-between-elements;
  }

  &-slider-social-vote-item__text {
    padding-left: 10px;
    padding-right: 10px;
  }

  &-slider-social-network {
    display: flex;
  }

  &-slider-social-network__title {
    padding-right: 15px;
  }

  &-slider-social-network-list {
    display: flex;
    margin-left: -@gutter-between-elements / 2;
    margin-right: -@gutter-between-elements / 2;
  }

  &-slider-social-network-item {
    padding-left: @gutter-between-elements / 2;
    padding-right: @gutter-between-elements / 2;
  }

  &-comment-block {
    margin-bottom: @block-margin-bottom;
  }

  &-comment-list {
    padding-left: 0;
    list-style: none;
    &:first-child {
      margin-top: 0;
    }
  }
  &-comment-item-text {
    overflow: hidden;
  }
  &-comment-item {
    margin-top: 15px;
  }
  &-comment-item-preview__img {
    display: block;
  }
  &-comment-item-text {
    display: table-cell;
    vertical-align: top;
  }
  &-comment-item-preview {
    display: table-cell;
    vertical-align: top;
  }
  &-comment-item-text {
    width: 10000px;
  }
  &-comment-item-text__title {
    margin-top: 0;
    margin-bottom: 5px;
  }

  /**
  * news-list
  * страница списка новостей
  * ---------------------------------------------------------------------------
  */
  &-page {

    &-sidebar {
      margin-bottom: @block-margin-bottom;
    }

    &-list-item-row {
      margin-bottom: @element-margin-bottom;
    }

    &-list-item {
      min-height: 320px;
      padding: 15px;
      margin-bottom: @block-margin-bottom;

      .news-border;
    }

    &-item-image-wrapper  {
      display: inline-block;
      vertical-align: top;
      width: 100%; /* ie11-fix */
      height: 250px;
      overflow: hidden;
      text-decoration: none;
    }

    &-item__image {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    &-item-content__title {
      display: inline-block;
      vertical-align: top;
      font-weight: 700;
      color: @heading-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &-keep-list {
      display: flex;
      margin-left: -@gutter-between-elements;
      margin-right: -@gutter-between-elements;
    }

    &-keep-item {
      padding-left: @gutter-between-elements;
      padding-right: @gutter-between-elements;
    }

    &-keep-item__link {
      min-width: 35px;
      padding-left: 0 !important;
      padding-right: 0 !important;
      text-align: center;
      color: @icon-color;
      border-color: @icon-color;
      border-width: 1px;

      transition: 0.15s background-color ease-in;

      &:hover {
        background-color: @icon-background-color-hover;
      }
    }

    &-sort-block {
      height: 100%;
      margin-bottom: @element-margin-bottom;
    }

    &-sort-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -@gutter-between-elements;
      margin-right: -@gutter-between-elements;
      margin-bottom: @element-margin-bottom;

      &--small {
        & .news-page-sort-list-item__link {
          font-size: 1rem;
          font-weight: 700;
        }
      }
      &-item {
        display: flex;
        align-items: baseline;
        padding-left: @gutter-between-elements;
        padding-right: @gutter-between-elements;
        &:last-child {
          &::after { content: none; }
        }
        &::after { content: attr(data-divider); }

        &__link {
          font-size: 1.25rem;
          text-decoration: none;
        }
      }
    }

  }


  /**
  * news-item
  * страница списка новостей
  * ---------------------------------------------------------------------------
  */
  &-item-page {

    &-similar-list-item {
      padding: 10px;
      margin-bottom: @element-margin-bottom * 2;

      .news-border;
    }

    &-return-link-wrapper {
      margin-bottom: @element-margin-bottom;
    }

    &-preview-image-wrapper {
      overflow: hidden;
      max-height: 1000px;
      margin-bottom: @element-margin-bottom;
    }

    &-preview__image {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;

      border-radius: @border-radius;
    }

    &-similar-list-item-image-wrapper {
      width: 100%;
      overflow: hidden;
      margin-bottom: @element-margin-bottom;
    }

    &-similar-list-item__image {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 500px; /* это контент, поэтому нужно как-то ограничить */
      border-radius: @border-radius;
    }

    &-similar-list-item__head {
      font-size: 24px;
    }

    &-item-text-info {
      line-height: 1.4;
      font-size: 0.95rem;
    }

    &-content-block {

      & iframe#video {
        max-width: 100%;
        margin-bottom: @block-margin-bottom;
      }

    }

  }
}
/**
* 1200 by default
*/
@media (max-width: @screen-lg) {
  .news {

    &-newsletter-form-wrapper {
      & .btn[type="submit"] {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
      }
    }
    &-slider-social-vote-list {
      margin-bottom: 20px;
    }
    &-slider-social-vote-item__link {
      padding-left: 10px;
      padding-right: 10px;
    }
    &-slider-social-action-wrapper {
      display: block;
    }

  }
} // close (max-width: @screen-lg)
/**
* 1024 by default
*/
@media (max-width: (@screen-md - 1) ) {
  .news {
    &-page-aside-wrapper {
      order: -10; /* пишем сразу "-10" из-за safari */
    }
    &-item-page-main-block-wrapper {
      order: -10; /* пишем сразу "10" из-за safari */
    }
    &-page-item-image-wrapper {
      margin-bottom: 20px;
    }
    &-page-item-item-text-info {
      margin-bottom: 20px;
    }
    &-item-link-wrapper {
      margin-bottom: 20px;
    }
    &-item-page-similar-list-item {
      border: 0;
    }
    &-item-page-similar-list-item-image-wrapper {
      height: 300px;
    }
    &-item-page-similar-list-item__image {
      max-height: 300px;
    }
    &-item-link-wrapper {
      justify-content: flex-start;
    }
  }
} // close (max-width: @screen-lg)
/**
* 767 by default
*/
@media (max-width: (@screen-sm - 1) ) {
  .news {

    &-slider-social-vote-list {
      display: block;
    }
    &-slider-social-vote-item {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-item-page-similar-list-item {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 20px;
    }

  }
} // close (max-width: @screen-lg)
