.mapbox {
  &-section {

  	margin-bottom: -40px;

    & > .container {

		max-width: none;

    }
  }

    position: relative;

    min-height: 520px;

    @media (max-width: 1024px) {
    	min-height: 300px;
    }

    & #marker1 {

		font-family: @main-font;
		font-size: 16px;
		font-weight: 300;

		background-color: rgba(255,255,255,0.9);

		padding-left: 80px;
		padding-right: 30px;

		padding-top: 10px;
		padding-bottom: 10px;


		border-radius: 50px;

		& p {

			margin-top: 0;
			margin-bottom: 0;

		}

		&:before {
			
			content: '';
			position: absolute;
			top: 0;
			left: -5px;

			width: 70px;
			height: 70px;

			background-image: url('../img/mapbox/marker-1.png');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;

		}

    }

}