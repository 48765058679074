@font-face {
  font-family: "roboto";
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url("../fonts/roboto/roboto-regular/roboto-regular.woff2") format("woff2"),
  url("../fonts/roboto/roboto-regular/roboto-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;  
}
@font-face {
  font-family: "roboto";
  font-display: swap;
  src: local('Roboto'), local('Roboto-Bold'), url("../fonts/roboto/roboto-bold/roboto-bold.woff2") format("woff2"),
  url("../fonts/roboto/roboto-bold/roboto-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;  
}

@font-face {
  font-family: "ProximaNova";
  font-display: swap;
  src: local('Proxima Nova Light'), local('ProximaNova-Light'), url("../fonts/ProximaNova/ProximaNova-Light/ProximaNova-Light.woff2") format("woff2"),
  url("../fonts/ProximaNova/ProximaNova-Light/ProximaNova-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;  
}
@font-face {
  font-family: "ProximaNova";
  font-display: swap;
  src: local('Proxima Nova Bold'), local('ProximaNova-Bold'), url("../fonts/ProximaNova/ProximaNova-Bold/ProximaNova-Bold.woff2") format("woff2"),
  url("../fonts/ProximaNova/ProximaNova-Bold/ProximaNova-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;  
}

@font-face {
  font-family: "Muller";
  font-display: swap;
  src: local('Muller'), local('MullerRegular'), url("../fonts/Muller/MullerRegular/MullerRegular.woff2") format("woff2"),
  url("../fonts/Muller/MullerRegular/MullerRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;  
}

@font-face {
  font-family: "Muller";
  font-display: swap;
  src: local('Muller'), local('MullerLight'), url("../fonts/Muller/MullerLight/MullerLight.woff2") format("woff2"),
  url("../fonts/Muller/MullerLight/MullerLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;  
}
/**
* rouble font
*/
// @font-face {
//     font-family: 'rouble';
//     src: url('../fonts/rouble-webfont.woff') format('woff'),
//          url('../fonts/rouble-webfont.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }
