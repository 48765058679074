@breadcrumb-slider-color-normal:       #fff;
@breadcrumb-slider-color-hover:        #fff;
@breadcrumb-slider-bg:                 transparent;
@breadcrumb-slider-text-transform:     none;
@breadcrumb-slider-font-weight:        400;
@breadcrumb-slider-font-size:          16px;
@breadcrumb-slider-line-height:        18px;
@breadcrumb-slider-padding-top:        0;
@breadcrumb-slider-padding-bottom:     0;
@breadcrumb-slider-icon-display:       inline-block; // "none" или "inline-block"
@breadcrumb-slider-separator:          '>';

@breadcrumb-slider-height:             ~"calc(100vh - 84px)";
@breadcrumb-slider-pt:                 0;
@breadcrumb-slider-pb:                 0;
@breadcrumb-slider-pl:                 0;
@breadcrumb-slider-pr:                 0;

@breadcrumb-slider-breadcrumb-pt:      50px;
@breadcrumb-slider-breadcrumb-pb:      50px;
@breadcrumb-slider-breadcrumb-pl:      70px;
@breadcrumb-slider-breadcrumb-pr:      70px;

@breadcrumb-slider-transition:         all ease-in-out .3s;

.breadcrumb-slider {

  z-index: 0;

  &__slide {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &-section {
    position: relative;

    height: @breadcrumb-slider-height;

    background-color: @breadcrumb-slider-bg;

    padding-top: @breadcrumb-slider-pt;
    padding-bottom: @breadcrumb-slider-pb;

    @media (min-width: 768px) and (max-width: 1199px) {
      height: 450px;
    }
    @media (min-width: 420px) and (max-width: 767px) {
      height: 400px;
    }
    @media (min-width: 1px) and (max-width: 419px) {
      height: 300px;
    }

    & h1 {
      color: @breadcrumb-slider-color-normal;
      line-height: 50px;

      margin-bottom: 0;
      text-shadow: 0 0 5px #000;

      @media (max-width: 1024px) {
        font-size: 34px;
        line-height: 34px;
      }
    }

    .breadcrumb-slider__breadcrumb {

      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      // width: auto;
      max-width: 100%;

      padding-top: @breadcrumb-slider-breadcrumb-pt;
      padding-bottom: @breadcrumb-slider-breadcrumb-pb;
      padding-left: @breadcrumb-slider-breadcrumb-pl;
      padding-right: @breadcrumb-slider-breadcrumb-pr;

      background: rgba(0, 0, 0, .6);

      @media (max-width: 1200px) {
        padding-left: 10px;
        padding-right: 10px;
      }
      @media (max-width: 767px) {

        max-width: unset;

      }

      & li {
        text-shadow: 0 0 5px #000;
      }

      & .breadcrumb__wrapper, & .heading__wrapper {
        // z-index: 1;
      }
      & a {
        font-size: @breadcrumb-slider-font-size;
        line-height: @breadcrumb-slider-line-height;
        font-weight: @breadcrumb-slider-font-weight;
        text-transform: @breadcrumb-slider-text-transform;
        color: @breadcrumb-slider-color-normal;
        text-decoration: none;
        padding-right: 5px;
        font-family: @alternative-font;
        transition: @breadcrumb-slider-transition;

        &[href] {
          & span {
            text-decoration: underline;
          }
        }

        & span {
          overflow: hidden;
        }

        &[href]:hover {
          color: @breadcrumb-slider-color-hover;
        }
        &::after {
          display: inline-block;
          content: @breadcrumb-slider-separator;
          padding-left: 5px;
          text-decoration: none;
        }

      }
      & .active a span {
        color: @breadcrumb-slider-color-hover;
      }
    }
  }
  height: 100%;
}

.breadcrumb-slider__breadcrumb {
  & .swiper-pagination {
    left: unset;
    right: @breadcrumb-slider-breadcrumb-pr;

    bottom: 50%;
    width: auto;
  }

  & .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: auto !important;
    transform: translateY(50%);
  }

  & .swiper-pagination-bullet {
    width: 13px;
    height: 13px;

    background-color: #fff;
    opacity: 0.5;

    transition: @breadcrumb-slider-transition;

    &-active {
      opacity: 1;
    }
  }
}


