html {
  height: 100%;
  box-sizing: border-box;
  outline: none;
}

*,*:before,*:after {box-sizing: inherit;}

body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  font-family: @main-font;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  min-height: 100%;
  background-color: #f1f1f3;
  overflow-x: hidden;
}

.main-content {
  flex-grow: 1;
  margin-top: -84px;
  padding-top: 168px;
}

.bg {
  background: #ffffff;
}

/**
* Основной контейнер
*/
.container {
  width: 100%;
  padding-left: @grid-gutter-width / 2;
  padding-right: @grid-gutter-width / 2;
  margin-left: auto;
  margin-right: auto;
}
.container-small {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.container-full {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.std-section {
  padding: 20px 60px;
  transition: all .3s ease-in-out;
  @media (min-width: 1025px) and (max-width: 1650px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (min-width: 1px) and (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
}
.full-section {
  padding: 20px 0;
}

/**
* общие кастомные стили
*/
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
button { cursor: pointer; }
address { font-style:normal; }
/**
* правила для плагина magnific popup
*/
html.no-scroll {
  overflow-y: hidden;
  padding-right: 15px;
}
.mfp-content {
  & .mfp-close {

    opacity: 1;

    & .icon {
      width: 25px;
      height: 25px;
      fill: @primary-color;
    }
  }
}

/**
* footer webmotor
*/
.webmotor {
  & span {
    color: #000;
  }
  & a {
    color: #000;
    text-decoration: underline;
    transition: 0.15s color ease-in;
  }
}

.page-head {
  margin-bottom: 20px;
}
