@slider-fulltext-section-pt: 40px;
@slider-fulltext-section-pb: 40px;

@slider-fulltext-height: auto;
@slider-fulltext-minh: 400px;
@slider-fulltext-height-res: auto;
@slider-fulltext-minh-res: 200px;

@slider-fulltext-arrow: @primary-color;

@slider-fulltext-bgc: #fff;

@slider-fulltext-pt: 30px;
@slider-fulltext-pb: 30px;
@slider-fulltext-pl: 30px;
@slider-fulltext-pr: 30px;

@slider-fulltext-pt-res: 16px;
@slider-fulltext-pb-res: 16px;
@slider-fulltext-pl-res: 16px;
@slider-fulltext-pr-res: 16px;

.slider-full-text {

  &-section {

    padding-top: @slider-fulltext-section-pt;
    padding-bottom: @slider-fulltext-section-pt;

    overflow-x: hidden;


  }

  &__wrapper {

    position: relative;

    width: 100%;
    height: @slider-fulltext-height;
    min-height: @slider-fulltext-minh;

    @media (max-width: 1024px) {
      
      height: @slider-fulltext-height-res;
      min-height: @slider-fulltext-minh-res;

    }    

  }

  &__inner {

    height: @slider-fulltext-height;
    min-height: @slider-fulltext-minh;

    @media (max-width: 1024px) {
      
      height: @slider-fulltext-height-res;
      min-height: @slider-fulltext-minh-res;

    }

    & .slick-dots {

      bottom: -30px;

      & button {

        position: relative;

        width: 22px;
        height: 22px;

        border: 2px solid #fff;

        &:before {

          content: '';
          
          width: 10px;
          height: 10px;
          background-color: transparent;

        }

      }

      & .slick-active button:before {

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        background-color: @primary-color;

      }

    }

  }

  &__item {

    height: @slider-fulltext-height;
    min-height: @slider-fulltext-minh;

    background-color: @slider-fulltext-bgc;

    padding-top: @slider-fulltext-pt;
    padding-bottom: @slider-fulltext-pb;
    padding-left: @slider-fulltext-pl;
    padding-right: @slider-fulltext-pr;

    @media (max-width: 1024px) {
      
      height: @slider-fulltext-height-res;
      min-height: @slider-fulltext-minh-res;

      padding-top: @slider-fulltext-pt-res;
      padding-bottom: @slider-fulltext-pb-res;
      padding-left: @slider-fulltext-pl-res;
      padding-right: @slider-fulltext-pr-res;

    }

  }

}