.mapbox-location {
  
  &-section {
  	position: relative;

  	display: -webkit-flex;
  	display: -moz-flex;
  	display: -ms-flex;
  	display: -o-flex;
  	display: flex;
  	flex-direction: column;

	flex-grow: 1;

	height: 100%;

	margin-bottom: -40px;

	& > .container {
		position: relative;

		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-direction: column;

		flex-grow: 1;

		height: 100%;

		& > .row {
			flex-grow: 1;
		}
	}
  }

  &__map {
	
	&_wrapper {

		position: relative;

		height: 100%;

		@media (max-width: 1023px) {
			min-height: 60vh;
		}

	}

	&_inner {

		height: 100%;

		& #mapbox-location {

			height: 100%;
			
		}

		& .mapboxgl-canvas {

			min-width: 100%;

		}

		& .mapboxgl-canvas-container {
			min-height: 100%;
		}

	}

  }

  &__marker {
  	position: relative;
  	top: -45px;
  	left: 180px;

  	min-width: 310px;
  	max-width: 310px;
  	min-height: 70px;

	font-family: @main-font;
	font-size: 15px;

  	background-color: rgba(255,255,255,0.9);

	padding-top: 8px;
	padding-bottom: 8px;
	padding-right: 20px;
	padding-left: 20px;

  	&:before {
  		content: '';
  		position: absolute;
  		bottom: -20px;
  		left: 0;
  		width: 0;
		height: 0;
		border-style: solid;
		border-width: 20px 20px 0 0;
		border-color: rgba(255,255,255,0.9) transparent transparent transparent;
  	}
  }

  &__menu {
	
	&_wrapper {
		height: 100%;

		background-color: #fafafa;

		min-height: 591px;

		max-width: 365px;

		@media (max-width: 1023px) {

			min-height: auto;
			max-width: none;

			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;

			background-color: #fff;

			height: auto;

		}

		@media (max-width: 767px) {
			flex-wrap: wrap;
		}
	}

	&_item {

		position: relative;

		border-bottom: 1px solid #e2e2e2;

		padding-left: 30px;
		padding-right: 70px;

		background-color: #fff;

		transition: background ease-in-out 0.1s;

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 10px;
    		width: 10px;
    		height: 100%;
			background-color: transparent;

			@media (max-width: 1023px) {

				content: unset;

			}

		}

		@media (max-width: 1024px) {
			padding-left: 20px;
			padding-right: 20px;
		}

		@media (max-width: 1023px) {
			
			border-bottom: none;

		}

		&-title {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			padding-top: 20px;
			padding-bottom: 20px;

			cursor: pointer;

			@media (max-width: 1023px) {
				
				justify-content: center;

			}

			& .caption {

				@media (max-width: 1023px) {

					display: none;
				}
			}

			& h3 {
				display: inline-block;

				margin-bottom: 0;

				@media (max-width: 767px) {
					font-size: 20px;
				}
			}

			& .icon {

				position: relative;
				width: 20px;
				height: 20px;

				margin-right: 17px;

				@media (max-width: 1023px) {

					margin-right: 0;

				}

				& img {

					display: block;
					width: 20px;
					height: 20px;

				}
				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 20px;
					height: 20px;
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center center;

					transition: background ease-in-out 0.1s;

				}
			}

			& svg {
				position: relative;
				top: 2px;

				display: block;

				width: 20px;
				height: 20px;
				margin-right: 17px;

				fill: #000;

				transition: all ease-in-out 0.1s;

				@media (max-width: 1023px) {

					margin-right: 0;

				}
			}

			&.active {

				& svg {

					fill: #fff;
					
				}
			}
		}

		&.all {			

			& .icon:before {
				
				background-image: url('../img/location/location_all.svg');

			}

			@media (min-width: 1024px) {

				&:hover {

					color: #fff;
					background-color: #1c002e;

					& .icon:before {

						background-image: url('../img/location/location_all-hover.svg');

					}

				}

			}

			&.active {

				&:before {

					background-color: #1c002e;

				}

				@media (max-width: 1023px) {

					background-color: #1c002e;

					& .icon:before {

						background-image: url('../img/location/location_all-hover.svg');

					}

				}

			}

		}

		&.kindergarden {		

			& .icon:before {
				
				background-image: url('../img/location/location_kindergarden.svg');

			}

			@media (min-width: 1024px) {

				&:hover {

					color: #fff;
					background-color: #dfba3f;

					& .icon:before {

						background-image: url('../img/location/location_kindergarden-hover.svg');

					}

				}

			}

			&.active {

				&:before {

					background-color: #dfba3f;

				}

				@media (max-width: 1023px) {

					background-color: #dfba3f;

					& .icon:before {

						background-image: url('../img/location/location_kindergarden-hover.svg');

					}

				}

			}

		}

		&.school {			

			& .icon:before {
				
				background-image: url('../img/location/location_school.svg');

			}

			@media (min-width: 1024px) {

				&:hover {

					color: #fff;
					background-color: #6865a7;

					& .icon:before {

						background-image: url('../img/location/location_school-hover.svg');

					}

				}

			}

			&.active {

				&:before {

					background-color: #6865a7;

				}

				@media (max-width: 1023px) {

					background-color: #6865a7;

					& .icon:before {

						background-image: url('../img/location/location_school-hover.svg');

					}

				}
			}

		}

		&.hospital {			

			& .icon:before {
				
				background-image: url('../img/location/location_hospital.svg');

			}

			@media (min-width: 1024px) {

				&:hover {

					color: #fff;
					background-color: #da4776;

					& .icon:before {

						background-image: url('../img/location/location_hospital-hover.svg');

					}

				}

			}

			&.active {

				&:before {

					background-color: #da4776;

				}

				@media (max-width: 1023px) {

					background-color: #da4776;

					& .icon:before {

						background-image: url('../img/location/location_hospital-hover.svg');

					}

				}

			}

		}

		&.drugs {			

			& .icon:before {
				
				background-image: url('../img/location/location_drugs.svg');

			}

			@media (min-width: 1024px) {

				&:hover {

					color: #fff;
					background-color: #dc47aa;

					& .icon:before {

						background-image: url('../img/location/location_drugs-hover.svg');

					}

				}

			}

			&.active {

				&:before {

					background-color: #dc47aa;

				}

				@media (max-width: 1023px) {

					background-color: #dc47aa;

					& .icon:before {

						background-image: url('../img/location/location_drugs-hover.svg');

					}

				}

			}

		}

		&.mall {			

			& .icon:before {
				
				background-image: url('../img/location/location_mall.svg');

			}

			@media (min-width: 1024px) {

				&:hover {

					color: #fff;
					background-color: #f77a32;

					& .icon:before {

						background-image: url('../img/location/location_mall-hover.svg');

					}

				}

			}

			&.active {

				&:before {

					background-color: #f77a32;

				}

				@media (max-width: 1023px) {

					background-color: #f77a32;

					& .icon:before {

						background-image: url('../img/location/location_mall-hover.svg');

					}

				}

			}

		}

		&.shop {			

			& .icon:before {
				
				background-image: url('../img/location/location_shop.svg');

			}

			@media (min-width: 1024px) {

				&:hover {

					color: #fff;
					background-color: #655b84;

					& .icon:before {

						background-image: url('../img/location/location_shop-hover.svg');

					}

				}

			}

			&.active {

				&:before {

					background-color: #655b84;

				}

				@media (max-width: 1023px) {

					background-color: #655b84;

					& .icon:before {

						background-image: url('../img/location/location_shop-hover.svg');

					}

				}

			}
	
		}

		&.cafe {			

			& .icon:before {
				
				background-image: url('../img/location/location_cafe.svg');

			}

			@media (min-width: 1024px) {

				&:hover {

					color: #fff;
					background-color: #52d99c;

					& .icon:before {

						background-image: url('../img/location/location_cafe-hover.svg');

					}

				}

			}

			&.active {

				&:before {

					background-color: #52d99c;

				}

				@media (max-width: 1023px) {

					background-color: #52d99c;

					& .icon:before {

						background-image: url('../img/location/location_cafe-hover.svg');

					}

				}

			}

		}

		&.park {			

			& .icon:before {
				
				background-image: url('../img/location/location_park.svg');

			}

			@media (min-width: 1024px) {

				&:hover {

					color: #fff;
					background-color: #7eb33a;

					& .icon:before {

						background-image: url('../img/location/location_park-hover.svg');

					}

				}

			}

			&.active {

				&:before {

					background-color: #7eb33a;

				}

				@media (max-width: 1023px) {

					background-color: #7eb33a;

					& .icon:before {

						background-image: url('../img/location/location_park-hover.svg');

					}

				}

			}

		}

		&.culture {			

			& .icon:before {
				
				background-image: url('../img/location/location_culture.svg');

			}

			@media (min-width: 1024px) {

				&:hover {

					color: #fff;
					background-color: #20d8d5;

					& .icon:before {

						background-image: url('../img/location/location_culture-hover.svg');

					}

				}

			}

			&.active {

				&:before {

					background-color: #20d8d5;

				}

				@media (max-width: 1023px) {

					background-color: #20d8d5;

					& .icon:before {

						background-image: url('../img/location/location_culture-hover.svg');

					}

				}

			}

		}

		&.sports {

			

			& .icon:before {
				
				background-image: url('../img/location/location_sports.svg');

			}

			@media (min-width: 1024px) {

				&:hover {

					color: #fff;
					background-color: #4aa6d7;

					& .icon:before {

						background-image: url('../img/location/location_sports-hover.svg');

					}

				}

			}

			&.active {

				&:before {

					background-color: #4aa6d7;

				}

				@media (max-width: 1023px) {

					background-color: #4aa6d7;

					& .icon:before {

						background-image: url('../img/location/location_sports-hover.svg');

					}

				}

			}

		}

		&:hover {

			& .mapbox-location__menu_item-title svg {

				fill: #fff;

			}

		}

		&.active {

			& .mapbox-location__menu_item-title svg {

				fill: #fff;

			}

		}

		&-main {
			display: none;
		}

	}

  }

}

body.ipad {
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
		& .mapbox-multiple-section {
			height: 502px;
		}
		& .mapboxgl-canvas-container, & .mapbox-multiple__map_wrapper, & .mapbox-multiple__menu_wrapper {

			min-width: 512px;
			height: 502px;
			min-height: 502px;
			max-height: 502px;

			overflow: hidden;

		}

		& .mapbox-multiple__menu_wrapper {
			overflow-y: auto;
		}
	}
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {

		& .mapbox-multiple-section {
			height: 1203px;
		}
		& .mapboxgl-canvas-container, & .mapbox-multiple__map_wrapper, & .mapbox-multiple__menu_wrapper {

			min-width: 512px;
			height: 615px;
			min-height: 615px;
			max-height: 615px;

			overflow: hidden;

		}

		& .mapbox-multiple__menu_wrapper {
			overflow-y: auto;
		}
	}
}
