@text-double-one-section-pt: 40px;
@text-double-one-section-pb: 40px;

@text-double-one-section-pt-res: 20px;
@text-double-one-section-pb-res: 20px;

@text-double-one-pt: 30px;
@text-double-one-pb: 30px;
@text-double-one-pl: 30px;
@text-double-one-pr: 30px;

@text-double-one-pt-res: 16px;
@text-double-one-pb-res: 16px;
@text-double-one-pl-res: 16px;
@text-double-one-pr-res: 16px;

@text-double-one-bgc: #fff;

.text {

  &-section.double.onehead {
    
    position: relative;

    padding-top: @text-double-one-section-pt;
    padding-bottom: @text-double-one-section-pb;

    @media (max-width: 767px) {

      padding-top: @text-double-one-section-pt-res;
      padding-bottom: @text-double-one-section-pb-res;

    }

    & .text__wrapper {

      background-color: @text-double-one-bgc;

      padding-top: @text-double-one-pt;
      padding-bottom: @text-double-one-pb;
      padding-left: @text-double-one-pl;
      padding-right: @text-double-one-pr;

      @media (max-width: 1199px) {
        padding-top: @text-double-one-pt-res;
        padding-bottom: @text-double-one-pb-res;
        padding-left: @text-double-one-pl-res;
        padding-right: @text-double-one-pr-res;
      }

    }
    
  }  

}