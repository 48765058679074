/**
* wm buttons
*/
// класс для сброса стилей браузера и создания общих стилей для кнопок
.btn,
.content .btn {
  @button-padding-top:                16px;
  @button-padding-bottom:             14px;
  @button-padding-left:               27px;
  @button-padding-right:              27px;

  @button-border-width:               2px;
  @button-border-style:               solid;
  @button-border-radius:              0;

  @button-font-size:                  14px;
  @button-font-weight:                400;

  @button-transition-duration:        0.3s;


  //## small button
  @button-sm-padding:                 4px 7px;
  @button-sm-border-radius:           0;
  @button-sm-font-size:               14px;

  //## button primary
  @button-primary-color:              #fff;
  @button-primary-bg:                 linear-gradient(to left, #048bfc  0%, #1bcde8 100%);
  @button-primary-border:             #ffbe0a;

  @button-primary-color-hover:        #fff;
  @button-primary-bg-hover:           linear-gradient(to left, #1bcde8 0%, #048bfc 100%);
  @button-primary-border-hover:       #efb003;

  @button-primary-border-radius:      23px;

  @button-primary-transition:         opacity 0.5s ease-in-out;


  //## button secondary
  @button-secondary-color:              #fff;
  @button-secondary-bg:                 @primary-color;
  @button-secondary-border:             @primary-color;

  @button-secondary-color-hover:        #fff;
  @button-secondary-bg-hover:           lighten(@primary-color, 10);
  @button-secondary-border-hover:       lighten(@primary-color, 10);

  //## button transparent
  @button-transparent-color:          #fff;
  @button-transparent-border:         #fff;

  @button-transparent-color-hover:    #2eadf5;
  @button-transparent-bg-hover:       #fff;
  @button-transparent-border-hover:   #fff;


  //## button disabled
  @button-disabled-bg-color:          #bcbcbc;
  @button-disabled-border-color:      #bcbcbc;
  @button-disabled-color:             #7f7f7f;


  //## button link
  @button-link-text-decoration:    none;

  @button-link-color:              @link-color;
  @button-link-bg:                 transparent;
  @button-link-border:             transparent;

  @button-link-color-hover:        @link-hover;
  @button-link-bg-hover:           transparent;
  @button-link-border-hover:       transparent;



  display: inline-block;

  padding-top: @button-padding-top;
  padding-bottom: @button-padding-bottom;
  padding-left: @button-padding-left;
  padding-right: @button-padding-right;

  border-width: @button-border-width;
  border-style: @button-border-style;
  border-radius: @button-border-radius;

  font-size: @button-font-size;
  font-weight: @button-font-weight;
  font-family: @alternative-font;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  line-height: 1;
  text-decoration: none;

  cursor: pointer;

  transition-duration: @button-transition-duration;
  transition-timing-function: ease-in;
  transition-property: border-color, background-color;

  &--sm {
    padding: @button-sm-padding;
    border-radius: @button-sm-border-radius;
    font-size: @button-sm-font-size;
  }

  &--primary {
    border: none;
    border-color: @button-primary-border;
    background-image: @button-primary-bg;
    color: @button-primary-color;
    border-radius: @button-primary-border-radius;
    position: relative;
    -webkit-backface-visibility: hidden;
    z-index: 1;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-color: @button-primary-border-hover;
      background-image: @button-primary-bg-hover;
      color: @button-primary-color-hover;
      transition: @button-primary-transition;
      border-radius: @button-primary-border-radius;
      z-index: 2;
      opacity: 0;
    }
    &:hover:after {
      opacity: 1;
    }
    & span {
      color: @button-primary-color;
      position: relative;
      z-index: 3;
    }
  }


  &--secondary {
    border-color: @button-secondary-border;
    background-color: @button-secondary-bg;
    color: @button-secondary-color;

    &:hover {
      border-color: @button-secondary-border-hover;
      background-color: @button-secondary-bg-hover;
      color: @button-secondary-color-hover;
    }
    &:visited {
      color: @button-secondary-color;
    }
  }


  &--link {
    border-color: @button-link-border;
    background-color: @button-link-bg;
    color: @button-link-color;
    text-decoration: @button-link-text-decoration;

    &:hover {
      border-color: @button-link-border-hover;
      background-color: @button-link-bg-hover;
      color: @button-link-color-hover;
    }
    &:focus {
      border-color: @button-link-border-hover;
      background-color: @button-link-bg-hover;
      color: @button-link-color-hover;
    }
    &:visited {
      color: @button-link-color;
    }
  }


  &--transparent {
    border-color: @button-transparent-border;
    background-color: transparent;
    color: @button-transparent-color;
    font-family: 'Muller', 'Arial', sans-serif;
    font-size: 15px;
    font-weight: 300;
    border-radius: 23px;
    padding: 13px 20px;
    position: relative;
    //&:hover {
    //  border-color: @button-transparent-border-hover;
    //  background-color: @button-transparent-bg-hover;
    //  color: @button-transparent-color-hover;
    //}
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-color: @button-primary-border-hover;
      background-image: @button-primary-bg-hover;
      color: @button-primary-color-hover;
      transition: @button-primary-transition;
      border-radius: @button-primary-border-radius;
      z-index: 2;
      opacity: 0;
    }
    &:hover:after {
      opacity: 1;
    }
    span {
      position: relative;
      z-index: 3;
      font-size: 13px;
      font-weight: 400;
      font-family: @alternative-font;
    }
    &:visited {
      color: @button-transparent-color;
    }
    &.btn--primary {
      border-color: @button-primary-border;
      color: @button-primary-border;
    }
    &.btn--secondary {
      border-color: @button-secondary-border;
      color: @button-secondary-border;
    }
  }


  &--disabled,
  &[disabled] {
    pointer-events: none; // ссылка не работает
    cursor: not-allowed;
    box-shadow: none;
    background-color: @button-disabled-bg-color;
    border-color: @button-disabled-border-color;
    color: @button-disabled-color;

    &:hover {
      border-color: @button-disabled-bg-color;
      background-color: @button-disabled-border-color;
      color: @button-disabled-color;
    }
    &:visited {
      color: @button-disabled-color;
    }
  }

}




@media (max-width: 1024px) {
  .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: @screen-sm) { /* 768px по умолчанию */
  .btn,
  .content .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
}
