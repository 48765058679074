// Collapsible panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.panel-group {
  margin-bottom: @paragraph-margin-bottom * 2;

  & .panel {
    margin-bottom: 20px;
    border-radius: 0;
  }

  .panel-title {
    margin-bottom: 0;

    & a {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 10px 15px;
      text-decoration: none;
      background-color: @gray;
      transition: 0.15s background-color ease-in;

      & .panel-title-link__text {
        color: @link-color;
      }

      &:hover .panel-title-link__text {
        color: @primary-color;
      }

      &:not(.collapsed) {
        & .panel-title-link__text {
          color: @primary-color;
          font-weight: 500;
          background-color: transparent;
        }

        & .panel-title-link__icon {
          & .icon {
            width: 35px;
            height: 35px;
            fill: @primary-color;
            transform: rotate(0);
          }
        }
      }

    }
  }

  & .panel-heading {
    border-bottom: 0;
  }

  .panel-footer {
    border-top: 0;
    + .panel-collapse .panel-body {
      border-bottom: 1px solid @gray--darken;
    }
  }
}

.panel-body {
  padding: 10px 15px 25px;
}
.panel-default {
  border: 1px solid @gray--darken;

  &.active {
    border-color: @primary-color;
    outline: 1px solid @primary-color;
  }
}
.collapse.in {
  display: block;
}
.collapse {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: .35s;
  transition-property: height,visibility;
}
.panel-title-link__icon {
  height: 31px;
  & .icon {
    width: 31px;
    height: 31px;
    fill: @link-color;
    transform: rotate(-45deg);
    transition: 0.15s transform ease-in;
  }
}
.panel-title-link__text {
  transition: 0.15s color ease-in;
}
