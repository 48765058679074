/**
* Таблица стилей содержит стили типографики для визуального редактора CKEditor
* переменные редактируются из файла ./src/less/variables.less
*/
/**
* Основные настройки контентной области
*/
.content {
  /**
  * учитывает последний margin-bottom у строчного элемента
  */
  height: 100%;
  color: @text-color;
}
/**
* Основной текст
*/
.content p,
.p {
  color: black;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: @paragraph-margin-bottom;

  &.lead {
    font-size: @font-size * 1.5;
  }
}
/**
* Заголовки
* -----------------------------------------------------------------------------
*/
.content .h1, .content h1, .h1, h1,
.content .h2, .content h2, .h2, h2,
.content .h3, .content h3, .h3, h3,
.content .h4, .content h4, .h4, h4,
.content .h5, .content h5, .h5, h5,
.content .h6, .content h6, .h6, h6 {
  font-family: @alternative-font;
  color: @heading-color;
  line-height: 1.1;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 12px;
  &.dots {
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px dotted @link-color;
    &:hover {
      border-color: @link-hover;
    }
  }
  &.dots-hover {
    text-decoration: none;
    transition: all ease-in-out 0.1s;
    &:hover {
      border-bottom: 1px dotted @link-color;
    }
  }
  & small { font-size: 65%;font-weight: 400;line-height: 1;color: @text-color;}
}

.content .h1, .content h1, .h1, h1 { font-size: @h1size;
  @media (min-width:1200px) and (max-width: 1599px){ font-size: @h1size * 0.90; }
  @media (min-width:1024px) and (max-width: 1199px){ font-size: @h1size * 0.85; }
  @media (min-width:1px) and (max-width: 1023px){ font-size: @h1size * 0.75; }

}
.content .h2, .content h2, .h2, h2 { font-size: @h2size;
  @media (min-width:1200px) and (max-width: 1599px){ font-size: @h2size * 0.90; }
  @media (min-width:1024px) and (max-width: 1199px){ font-size: @h2size * 0.85; }
  @media (min-width:1px) and (max-width: 1023px){ font-size: @h2size * 0.75; }

}
.content .h3, .content h3, .h3, h3 { font-size: @h3size;
  @media (min-width:1200px) and (max-width: 1599px){ font-size: @h3size * 0.90; }
  @media (min-width:1024px) and (max-width: 1199px){ font-size: @h3size * 0.85; }
  @media (min-width:1px) and (max-width: 1023px){ font-size: @h3size * 0.75; }

}
.content .h4, .content h4, .h4, h4 { font-size: @h4size;
  @media (min-width:1200px) and (max-width: 1599px){ font-size: @h4size * 0.90; }
  @media (min-width:1024px) and (max-width: 1199px){ font-size: @h4size * 0.85; }
  @media (min-width:1px) and (max-width: 1023px){ font-size: @h4size * 0.75 }

}
.content .h5, .content h5, .h5, h5 { font-size: @h5size;
  @media (min-width:1200px) and (max-width: 1599px){ font-size: @h5size * 0.95; }
  @media (min-width:1025px) and (max-width: 1199px){ font-size: @h5size * 0.90; }
  @media (min-width:1px) and (max-width: 1023px){ font-size: @h5size * 0.85; }

}
.content .h6, .content h6, .h6, h6 { font-size: @h6size;
  @media (min-width:1200px) and (max-width: 1599px){ font-size: @h6size * 0.95; }
  @media (min-width:1024px) and (max-width: 1199px){ font-size: @h6size * 0.90; }
  @media (min-width:1px) and (max-width: 1023px){ font-size: @h6size * 0.85; }

}
/**
* Оформление текста внутри абзаца
* -----------------------------------------------------------------------------
*/
b,
strong {
  font-weight: bolder;
}

cite,
dfn,
em,
i,
var {
  font-style: italic;
}

.mark,
mark {
  padding: 0.2rem;
  color: @text-color;
  /**
  * обязательно требуется контрастное значение
  */
  background-color: #ff0;
}
/**
* листинги кода
* -----------------------------------------------------------------------------
*/
code,
pre {
  font-size: 0.9rem;
  padding: 3px 8px 1px;
  background-color: #fff;
  border: 1px solid #E1E1E1;
  border-radius: 3px;
  color: #c7254e;
}

code,
kbd,
pre,
samp {
  font-family: @mono-font;
}
/**
* ссылки
* -----------------------------------------------------------------------------
*/
.a,
.content a {
  color: @link-color;
  font-weight: 400;
  text-decoration: underline;
  transition: 0.3s color ease;

  &:active {
    color: @link-active;
  }

  &:visited:not(.btn) {
    color: @link-visited;
  }

  &.hover,
  &:hover {
    color: @link-hover;
  }
  &.dots {
    text-decoration: none;
    border-bottom: 1px dotted @link-color;
    transition: all ease-in-out 0.3s;
    &:hover {
      border-color: @link-hover;
    }
  }
  &.dots-hover {
    text-decoration: none;
    transition: all ease-in-out 0.3s;
    &:hover {
      border-bottom: 1px dotted @link-color;
    }
  }
}
/**
* списки
* -----------------------------------------------------------------------------
*/
.content {
  @list-margin-bottom:                   @paragraph-margin-bottom;

  & ul {
    //# unordered list
    @unordered-list-margin-left:           0;
    @unordered-list-padding-left:          0;
    //# unordered item
    @unordered-item-padding-left:          28px;
    @unordered-list-line-height:           24px;
    @unordered-list-item-marker-top:       (@unordered-list-line-height / 2) - (@unordered-list-item-marker-height / 2);
    @unordered-list-item-marker-width:     10px;
    @unordered-list-item-marker-height:    10px;
    @unordered-list-item-border-radius:    5px;
    @unordered-list-marker-color:          @primary-color;

    margin: 0 0 @list-margin-bottom @unordered-list-margin-left;
    padding: 0 0 0 @unordered-list-padding-left;
    list-style-type: none;

    & li {
      position: relative;
      margin-bottom: 0;
      line-height: @unordered-list-line-height;
      /**
      * место под маркер списка
      */
      padding-left: @unordered-item-padding-left;
    }

    & ul {
      margin: 0;
      padding-top: 0;
      padding-left: 0;

      & li {
        margin-bottom: 0;

        &::before {
          background-color: transparent;
        }
      }
    }

    & ol {
      margin: 0;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-top: 0;
      padding-left: 0;

      & li {
        margin-bottom: 0;

        &::before {
          //background-color: transparent;
        }
      }
    }

    & > li::before {
      content: '';
      position: absolute;
      top: @unordered-list-item-marker-top;
      left: 0;
      width: @unordered-list-item-marker-width;
      height: @unordered-list-item-marker-height;
      background-color: @unordered-list-marker-color;
      border: 1px solid @unordered-list-marker-color;
      border-radius: @unordered-list-item-border-radius;
    }
  }

  & ol {
    //# ordered list
    @ordered-list-padding-left:            4px;
    //# ordered item
    @ordered-list-item-padding-left:       28px; // место под маркер списка
    @ordered-list-item-marker-color:       @primary-color;
    @ordered-item-marker-font-size:        18px;
    @ordered-item-line-height:             24px;
    @ordered-item-markger-font-weight:     700;

    margin: 0 0 @list-margin-bottom;
    padding: 0 0 0 @ordered-list-padding-left;
    list-style-type: none;
    /**
    * Задаём имя счетчика
    */
    counter-reset: num;
    & li {
      position: relative;
      padding-left: @ordered-list-item-padding-left;
      margin-bottom: 0;
    }

    & ol {
      margin: 0;
      padding-left: 10px;
      padding-top: 0;
      list-style-type: none;
      /**
      * Задаём имя счетчика
      */
      counter-reset: num;
      & li {
        margin-bottom: 0;
        line-height: @ordered-item-line-height;
      }
      /**
      * многоуровневый нумерованный пункт списка
      */
      li::before {
        /**
        * Выводим число
        */
        content: counter(num) '.';
        /**
        * Увеличиваем значение счётчика
        */
        counter-increment: num;
        display: inline-block;
        vertical-align: baseline;
        margin-right: 2px;
        color: @ordered-list-item-marker-color;
        font-size: @ordered-item-marker-font-size;
        font-weight: @ordered-item-markger-font-weight;
        line-height: @ordered-item-line-height;
      }
    }

    & ul {
      margin: 0;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 10px;
      padding-top: 0;
      list-style-type: none;
      counter-reset: num;     /* Задаём имя счетчика */

      & li {
        margin-bottom: 0;
        line-height: @ordered-item-line-height;
      }
      
    }

    & > li::before {
      /**
      * Выводим число
      */
      content: counter(num) '.';
      /**
      * Увеличиваем значение счётчика
      */
      counter-increment: num;
      position: absolute;
      top: 0;
      left: 0;
      margin-right: 5px;
      width: 22px;
      text-align: right;
      color: @ordered-list-item-marker-color;
      font-size: @ordered-item-marker-font-size;
      font-weight: @ordered-item-markger-font-weight;
      line-height: @ordered-item-line-height;
    }
  }
}
/**
* таблица
* -----------------------------------------------------------------------------
*/
.content {
  & table {
    width: 100%;
  }
}
/**
* только для настоящих таблиц
*/
table.table {
  @table-striped-background-color:       #f5f5f5; // цвета фона полос таблицы
  @table-tr-hover-background-color:      #f5f5f5;

  //# размеры ячеек таблицы
  @table-cell-padding-top:               6px;
  @table-cell-padding-bottom:            6px;
  @table-cell-padding-left:              20px;
  @table-cell-padding-right:             20px;

  //# параметры текста таблицы
  @table-th-vertical-align:              middle;
  @table-th-text-align:                  left;
  @table-th-font-weight:                 700;
  @table-th-color:                       @primary-color;

  @table-td-vertical-align:              middle;
  @table-td-text-align:                  left;
  @table-td-font-weight:                 400;

  table-layout: fixed;
  border-spacing: 2px 2px; // 2 значения по оси x, y
  margin-bottom: 50px;


  &.table-striped {
    & tr:nth-child(even) {
      background-color: @table-striped-background-color;
    }
  }

  &.table-hover {
    & tr:hover {
      background-color: @table-tr-hover-background-color;
    }
  }

  & th {
    padding: @table-cell-padding-top @table-cell-padding-right @table-cell-padding-bottom @table-cell-padding-left;
    font-weight: @table-th-font-weight;
    color: @table-th-color;
    text-align: @table-th-text-align;
    vertical-align: @table-th-vertical-align;
  }

  & td {
    padding: @table-cell-padding-top @table-cell-padding-right @table-cell-padding-bottom @table-cell-padding-left;
    font-weight: @table-td-font-weight;
    text-align: @table-td-text-align;
    vertical-align: @table-td-vertical-align;
  }
}
/**
* цитата blockquote
* -----------------------------------------------------------------------------
*/
.content {
  & blockquote {
    @blockquote-font-size:                 @font-size * 1.25;

    font-size: @blockquote-font-size;
    border-left-color: @gray;
    border-left-style: solid;
    border-left-width: 5px;
    padding: 12px 2%;
    margin: 0 2% 20px;

    & footer,
    & small {
      font-size: @blockquote-font-size * 0.8;

      &::before {
        content: '\2014 \00A0';
      }
    }
  }
}
/**
* элемент важно и комментарий
* -----------------------------------------------------------------------------
*/
.content .important,
.important {
  background-color: #f3481a;
  color: #fff;
  padding: 32px 8.5%;
  margin-bottom: 40px;

  & h3 {
    color: #fff;
  }

  & p {
    margin-bottom: 0;
  }
}

.comment,
.content .comment {
  background-color: #244373;
  color: #fff;
  padding: 32px 8.5%;
  margin-bottom: 40px;

  & .h3,
  & h3 {
    color: #fff;
  }

  & p {
    margin-bottom: 0;
    font-style: italic;
  }
}
/**
* сноски (footnotes)
* -----------------------------------------------------------------------------
*/
.content {
  .footnote {
    color: @text-color;
    text-decoration: none;

    &:hover {
      color: @link-hover;
    }

    &:visited {
      color: @text-color;
    }

    & sup {
      color: @link-hover;
    }
  }

  .footnotes {
    & li {
      font-size: 14px;

      &::before {
        font-size: 14px;
      }
    }
  }
}

.content img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 1024px) {
  code,
  pre {
    display: block;
    overflow-x: scroll;
    padding: 10px;
  }

  .content table.table {
    width: auto;
    max-width: 100%;
  }

  .table-responsive {
    /**
    * тень выравнивается относительно этого блока
    */
    position: relative;
    /**
    * нужно для того чтоб тень не прокручивалась
    */
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .table-responsive div {
    /**
    * этот див нужен для тени
    */
    overflow-x: auto;
  }

  /**
  * тень остается на одном месте благодаря вложенному диву
  */
  .table-responsive div::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 30px;
    height: 100%;
    /**
    * тильда и ковычки это для less-файлов
    */
    border-radius: ~"10px 0 0 10px / 50% 0 0 50%";
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.25);
  }

  .content ol,
  .content ul {
    margin-left: 1%;
    padding-left: 1%;
  }

  .content ol ol,
  .content ul ul {
    padding-left: 0;
  }

  .content ul li {
    padding-left: 25px;
  }
}
