
.gallery-item-page-controls-wrapper {
  & select.js-select2 {
    width: 100%;
    height: 32px;
    color: #646464;
    background-color: #ececec;
    box-shadow: inset 0 0 24px rgba(0, 0, 0, 0.05);
    border: 1px solid #aaa;
    border-radius: 1px;
  }
  & .select2 {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.gallery-item-page-arrow {
  &.mfp-arrow-left {
    left: -20px;
  }
  &.mfp-arrow-right {
    right: -20px;
  }

  &.mfp-arrow {
    svg {
      fill: @primary-color;
    }

  }

  &.mfp-arrow-left {
    & svg {
      transform: rotateZ(90deg);
    }
    &::before {
      border-color: transparent;
    }
    &::after {
      top: -30px;
      width: 73px;
      height: 100px;
      margin-left: 12px;
      border-top-width: 0;
      border-bottom-width: 0;
      opacity: 0.7;
      border-color: transparent;

    }
  }
  &.mfp-arrow-right {
    & svg {
      transform: rotateZ(-90deg);
    }
    &:before {
      border-color: transparent;
    }
    &::after {
      top: -30px;
      width: 73px;
      height: 100px;
      margin-right: 12px;
      margin-left: 0;
      border-top-width: 0;
      border-bottom-width: 0;
      opacity: 0.7;
      border-color: transparent;
    }
  }
}

.gallery-item-page-item {
  margin-bottom: @grid-gutter-width;

  &-link {
    display: block;
    width: 100%;
    height: 285px;
    overflow: hidden;

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      font-family: 'object-fit: cover;';
    }
  }
}
