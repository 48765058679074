@pictext-slider-section-first-pt: 20px;
@pictext-slider-section-last-pb: 20px;

@pictext-slider-pic-minh: 420px;

@pictext-slider-arrow: #fff;
@pictext-slider-arrow-bg: @primary-color;

@pictext-slider-text-bgc: #fff;
@pictext-slider-text-pt: 30px;
@pictext-slider-text-pb: 30px;
@pictext-slider-text-pl: 30px;
@pictext-slider-text-pr: 30px;

@pictext-slider-text-pt-res: 16px;
@pictext-slider-text-pb-res: 16px;
@pictext-slider-text-pl-res: 16px;
@pictext-slider-text-pr-res: 16px;

@pictext-slider-minh-high  : 800px;
@pictext-slider-minh-1024  : 600px;
@pictext-slider-minh-768   : 300px;

.pictext-slider {

  &__wrapper {

    position: relative;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;

    &.pic {

      position: relative;

      display: block;

      min-height: @pictext-slider-pic-minh;

      & .pictext-slider__nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;

        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: space-between;

        width: 100%;

        z-index: 55;

        & .pictext-slider__arrow {
          position: relative;

          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          justify-content: center;
          align-items: center;

          width: 50px;
          height: 50px;

          background-color: @pictext-slider-arrow-bg;

          cursor: pointer;

          &.pictext-slider__prev {
            transform: rotateY(180deg);
            margin-right: 1px;
          }

          & svg {
            width: 25px;
            height: 10px;

            fill: @pictext-slider-arrow;
          }
        }
      }

    }
    &.text {

      background-color: @pictext-slider-text-bgc;

      padding-top: @pictext-slider-text-pt;
      padding-bottom: @pictext-slider-text-pb;
      padding-left: @pictext-slider-text-pl;
      padding-right: @pictext-slider-text-pr;

      @media (max-width: 1199px) {
        padding-left: @pictext-slider-text-pl-res;
        padding-right: @pictext-slider-text-pr-res;
      }

      & .content {

        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        & h1, & h2, & h3, & h4, & h5, & h6, & .h1, & .h2, & .h3, & .h4, & .h5, & .h6,
        & p, & .p, & ul, & ol, & span {

          align-self: stretch;

        }

      }

    }
  }

  &__inner {

    height: 100%;

  }

  &__img {

    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &.contain {
      background-size: contain;
    }

  }

  &-section {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;

    &:first-child, &:first-of-type {
      
      padding-top: @pictext-slider-section-first-pt;

    }

    &:last-child, &:last-of-type {
      
      padding-bottom: @pictext-slider-section-last-pb;

    }

    &.high {

      & .pic {
        min-height: @pictext-slider-minh-high;

        @media (min-width: 769px) and (max-width: 1024px) {
          min-height: @pictext-slider-minh-1024;
        }
        @media (min-width: 1px) and (max-width: 768px) {
          min-height: @pictext-slider-minh-768;
        }
      }
    }
    &.left {
      & .pictext-slider__order_pic {
        order: 1;
        @media (max-width: 767px) {
          order: unset;
        }
      }
      & .pictext-slider__order_text {
        order: 2;
        @media (max-width: 767px) {
          order: unset;
        }
      }
    }
    &.right {
      & .pictext-slider__order_pic {
        order: 2;
        @media (max-width: 767px) {
          order: unset;
        }
      }
      & .pictext-slider__order_text {
        order: 1;
        @media (max-width: 767px) {
          order: unset;
        }
      }
    }
  }
}

body.ipad {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    & .pictext-slider__wrapper.pic, & .pictext-slider__wrapper.text {
      height: 420px;
    }
    & .pictext-slider__wrapper.pic .pictext-slider__nav {
      height: 50px;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    & .pictext-slider__wrapper.pic, & .pictext-slider__wrapper.text {
      height: 520px;
    }
    & .pictext-slider__wrapper.pic .pictext-slider__nav {
      height: 50px;
    }
  }
  
}
