.contacts {
  padding-bottom: 40px;
}
.contacts-single {
  padding-bottom: 40px;
}
.contacts__map {
  height: 100%;
  flex-grow: 1;
  #map {
    height: 516px;
    flex-grow: 1;
  }
  #map-multiple {
    height: 395px;
    flex-grow: 1;
  }
}
.contacts-single__map {
  height: 100%;
  flex-grow: 1;
  #map {
    height: 516px;
    flex-grow: 1;
  }
  #map-multiple {
    height: 395px;
    flex-grow: 1;
  }
}
.contacts__map_wrapper {
  padding-left: 0;
}
.contacts-single__map_wrapper {
  padding-left: 0;
}
.contacts__map-lg {
  height: 500px;
  flex-grow: 1;
  margin-bottom: 20px;
  #map {
    height: 500px;
    flex-grow: 1;
  }
}
.contacts-single__map-lg {
  height: 500px;
  flex-grow: 1;
  margin-bottom: 20px;
  #map {
    height: 500px;
    flex-grow: 1;
  }
}
.contacts__map-sm {
  height: 100%;
  flex-grow: 1;
  margin-bottom: 0;
  #map {
    height: 100%;
    flex-grow: 1;
  }
}
.contacts-single__map-sm {
  height: 100%;
  flex-grow: 1;
  margin-bottom: 0;
  #map {
    height: 100%;
    flex-grow: 1;
  }
}
.contacts__tab_wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.contacts-single__tab_wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.contacts__tab_item {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}
.contacts-single__tab_item {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}
.contacts__info {
  margin-bottom: 20px;
  background: #fff;
  .contacts-single-info__item {
    font-family: "ProximaNova", Helvetica, Arial;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:nth-child(2) {
      a {
        color: #333;
        font-family: "ProximaNova", Helvetica, Arial;
        font-size: 18px;
        font-weight: 300;
        line-height: 18px;
        &:hover {
          color: @link-hover;
        }
      }
    }
    &:nth-child(4) {
      a {
        &:nth-child(3) {
          display: inline-block;
          color: @link-hover;
          font-family: "ProximaNova", Helvetica, Arial;
          font-size: 18px;
          font-weight: 300;
          line-height: 18px;
          text-decoration: none;
          border-bottom: 1px solid @link-hover;
          margin-bottom: 5px;
          &:hover {
            color: @link-color;
            border-bottom: 1px solid transparent;
            text-decoration: none;
          }
        }
      }
    }
  }
  p {
    margin-bottom: 10px;
  }
}
.contacts-single__info {
  margin-bottom: 0;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .contacts-single-info__item {
    font-family: "ProximaNova", Helvetica, Arial;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:nth-child(2) {
      a {
        color: #333;
        font-family: "ProximaNova", Helvetica, Arial;
        font-size: 18px;
        font-weight: 300;
        line-height: 18px;
        &:hover {
          color: @link-hover;
        }
      }
    }
    &--mail a {
      color: #333;
      font-family: "ProximaNova", Helvetica, Arial;
      font-size: 18px;
      font-weight: 300;
      line-height: 18px;
      &:hover {
        color: @link-hover;
      }
    }
    &:nth-child(4) {
      a {
        &:nth-child(3) {
          display: inline-block;
          color: @link-hover;
          font-family: "ProximaNova", Helvetica, Arial;
          font-size: 18px;
          font-weight: 300;
          line-height: 18px;
          text-decoration: none;
          border-bottom: 1px solid @link-hover;
          margin-bottom: 5px;
          &:hover {
            color: @link-color;
            border-bottom: 1px solid transparent;
            text-decoration: none;
          }
        }
      }
    }
  }
  p {
    margin-bottom: 10px;
  }
}
.contacts__social {
  a {
    display: inline-block;
    font-size: 25px;
    color: @link-color;
    margin-right: 20px;
    transition: color ease-in-out 0.2s;
    &:last-child {
      margin-right: 0;
    }
    &:visited {
      &:not(.btn) {
        color: @link-color;
      }
    }
    &:active {
      &:not(.btn) {
        color: @link-active;
      }
    }
    &:hover {
      &:not(.btn) {
        color: @link-hover;
      }
    }
  }
}
.contacts-single__social {
  a {
    display: inline-block;
    font-size: 25px;
    color: @link-color;
    margin-right: 20px;
    transition: color ease-in-out 0.2s;
    &:last-child {
      margin-right: 0;
    }
    &:visited {
      &:not(.btn) {
        color: @link-color;
      }
    }
    &:active {
      &:not(.btn) {
        color: @link-active;
      }
    }
    &:hover {
      &:not(.btn) {
        color: @link-hover;
      }
    }
  }
}
.contacts__employee {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 20px;
  background: #fff;
  width: 100%;
  .img {
    width: auto;
    height: auto;
    min-width: 120px;
    min-height: 120px;
    max-width: 100%;
    max-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}
.contacts-single__employee {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 20px;
  background: #fff;
  width: 100%;
  .img {
    width: auto;
    height: auto;
    min-width: 120px;
    min-height: 120px;
    max-width: 100%;
    max-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}
.contacts__employee_height {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.contacts-single__employee_height {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.contacts__employee_wrapper {
  position: relative;
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.contacts-single__employee_wrapper {
  position: relative;
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.contacts__employee_info {
  padding-top: 35px;
  color: #000;
  font-weight: 400;
  line-height: 31px;
  .h3 {
    font-size: 25px;
    margin-bottom: 4px;
  }
  a.employee-telephone {
    display: block;
    color: #000 !important;
    font-size: 16px;
    text-decoration: none !important;
    font-weight: 300;
    line-height: 29px;
  }
  span {
    color: #8c8c8c;
    font-size: 16px;
    font-weight: 300;
    line-height: 29px;
    margin-bottom: 30px;
    display: inline-block;
  }
  p {
    margin-bottom: 0;
  }
}
.contacts-single__employee_info {
  padding-top: 35px;
  color: #000;
  font-weight: 400;
  line-height: 31px;
  flex-grow: 1;
  padding-bottom: 20px;
  padding-left: 25px;
  .h3 {
    font-size: 25px;
    margin-bottom: 4px;
  }
  a.employee-telephone {
    display: block;
    color: #000 !important;
    font-size: 16px;
    text-decoration: none !important;
    font-weight: 300;
    line-height: 29px;
  }
  span {
    color: #8c8c8c;
    font-size: 16px;
    font-weight: 300;
    line-height: 29px;
    margin-bottom: 30px;
    display: inline-block;
    border-bottom: 1px solid #ebebeb;
    width: 100%;
    margin-left: -25px;
    padding-left: 25px;
  }
  p {
    margin-bottom: 0;
  }
}
.contacts-info__item {
  padding-left: 20px;
}
.contacts-single-info__item {
  margin-bottom: 9px;
  color: #000;
  font-size: 16px;
  font-weight: 300;
  line-height: 29px;
  border-bottom: 1px solid #ebebeb;
  padding-left: 20px;
  padding-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 25px;
  }
  .footer-social-list-item {
    height: 26px;
  }
  .footer-social-list-item__link {
    margin-right: 0;
    &:hover {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    svg {
      fill: #c5c4c3;
      width: 40px;
      height: 40px;
      &:hover {
        fill: darken(#c5c4c3, 10);
      }
    }
  }
  span {
    color: #333;
    font-family: "ProximaNova", Helvetica, Arial;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }
  .a {
    color: #333;
    font-family: "ProximaNova", Helvetica, Arial;
    font-size: 18px;
    font-weight: 300;
    line-height: 18px;
    &:visited {
      &:not(.btn) {
        color: @link-color;
      }
    }
    &:active {
      &:not(.btn) {
        color: @link-active;
      }
    }
    &:hover {
      &:not(.btn) {
        color: @link-hover;
      }
    }
  }
}
.contacts-single__info_wrapper {
  margin-left: 0;
  margin-right: 0;
  background-color: #fff;
}
.left-bottom-triangle {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: -85px;
    width: 0;
    height: 0;
    border-bottom: 85px solid #fff;
    border-right: 85px solid transparent;
  }
}
@media screen and (max-width: 1024px) {
  .contacts__map_wrapper {
    padding-left: 10px;
  }
  .contacts-single__map_wrapper {
    padding-left: 10px;
  }
  .contacts__info {
    margin-bottom: 0;
  }
  .contacts-single__info {
    margin-bottom: 0;
  }
  .contacts-single-info__item {
    padding-left: 10px;
  }
}
@media screen and (max-width: 1023px) {
  .contacts__map-sm {
    #map {
      height: 400px;
    }
  }
  .contacts-single__map-sm {
    #map {
      height: 400px;
    }
  }
}
@media screen and (max-width: 768px) {
  .contacts__employee {
    .img {
      width: 100%;
      height: auto;
      min-height: 200px;
      max-width: 100%;
      max-height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }
  .contacts-single__employee {
    .img {
      width: 100%;
      height: auto;
      min-height: 200px;
      max-width: 100%;
      max-height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }
  .contacts__employee_info {
    padding-top: 15px;
    padding-left: 0;
    margin: 0 auto;
  }
  .contacts-single__employee_info {
    padding-top: 15px;
    padding-left: 0;
    margin: 0 auto;
    padding-left: 0;
  }
}
@media (min-width: 767px) {
  .contacts__info_wrapper > div:first-child {
    padding-right: 0;
    display: flex;
    flex-grow: 1;
  }
  .contacts__info_wrapper > div:last-child {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .contacts-single__employee {
    display: block;
    text-align: center;
    .img {
      margin: 0 auto;
    }
  }
  .contacts-single__info {
    padding-left: 0;
    .footer-social-list {
      justify-content: flex-start;
    }
  }
  .contacts-single__tab_wrapper {
    flex-direction: column;
  }
  .contacts-single__tab_item {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-right: 0;
  }
}
