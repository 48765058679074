@text-double-two-section-pt: 40px;
@text-double-two-section-pb: 40px;

@text-double-two-section-pt-res: 20px;
@text-double-two-section-pb-res: 20px;

@text-double-two-pt: 30px;
@text-double-two-pb: 30px;
@text-double-two-pl: 30px;
@text-double-two-pr: 30px;

@text-double-two-pt-res: 16px;
@text-double-two-pb-res: 16px;
@text-double-two-pl-res: 16px;
@text-double-two-pr-res: 16px;

@text-double-two-bgc: #fff;

.text {

  &-section.double.twohead {
    
    position: relative;

    padding-top: @text-double-two-section-pt;
    padding-bottom: @text-double-two-section-pb;

    @media (max-width: 767px) {

      padding-top: @text-double-two-section-pt-res;
      padding-bottom: @text-double-two-section-pb-res;

    }

    & .text__wrapper {

      background-color: @text-double-two-bgc;

      padding-top: @text-double-two-pt;
      padding-bottom: @text-double-two-pb;
      padding-left: @text-double-two-pl;
      padding-right: @text-double-two-pr;

      @media (max-width: 1199px) {
        padding-top: @text-double-two-pt-res;
        padding-bottom: @text-double-two-pb-res;
        padding-left: @text-double-two-pl-res;
        padding-right: @text-double-two-pr-res;
      }

    }
    
  }

}