@img-section-double-pt: 40px;
@img-section-double-pb: 40px;

@img-section-double-pt-res: 20px;
@img-section-double-pb-res: 20px;

@img-double-scale: 50%;
@img-double-bgp: center center;

@img-double-mb-res: 20px;

.img {

  &-section.double {
    
    position: relative;

    padding-top: @img-section-double-pt;
    padding-bottom: @img-section-double-pb;

    @media (max-width: 767px) {

      padding-top: @img-section-double-pt-res;
      padding-bottom: @img-section-double-pb-res;

    }

    & .img__container {

		@media (max-width: 767px) {

			margin-bottom: @img-double-mb-res;

			&:last-child {

				margin-bottom: 0;

			}

	    }

    }

    & .img__wrapper {

    	position: relative;

    	height: 0;

	    padding-bottom: @img-double-scale;

	    overflow: hidden;

	    & img {

	    	display: block;

	    	position: absolute;
	    	top: 50%;
	    	left: 50%;
	    	transform: translateX(-50%) translateY(-50%);

			width: 100%;
			max-width: 100%;
			height: auto;

	    }

	}

	&.bg {

		& .img__wrapper {

			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-direction: column;
			flex-grow: 1;

			padding-bottom: @img-double-scale;

			background-repeat: no-repeat;
			background-size: cover;
			background-position: @img-double-bgp;

		}

	}
    
  }

}