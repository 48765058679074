@pictextpic-section-pt: 20px;
@pictextpic-section-pb: 20px;

@pictextpic-pic-minh: 420px;

@pictextpic-text-bgc: #fff;
@pictextpic-text-pt: 30px;
@pictextpic-text-pb: 30px;
@pictextpic-text-pl: 30px;
@pictextpic-text-pr: 30px;

@pictextpic-text-pt-res: 16px;
@pictextpic-text-pb-res: 16px;
@pictextpic-text-pl-res: 16px;
@pictextpic-text-pr-res: 16px;

.pictextpic {

  &__wrapper {

    position: relative;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;

    &.pic {

      position: relative;

      display: block;

      height: 100%;
      min-height: @pictextpic-pic-minh;

    }
    &.text {

      background-color: @pictextpic-text-bgc;

      padding-top: @pictextpic-text-pt;
      padding-bottom: @pictextpic-text-pb;
      padding-left: @pictextpic-text-pl;
      padding-right: @pictextpic-text-pr;

      @media (max-width: 1199px) {
        padding-left: @pictextpic-text-pl-res;
        padding-right: @pictextpic-text-pr-res;
      }

    }
  }

  &__inner {

    height: 100%;

  }

  &__img {

    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

  }

  &-section {
    
    position: relative;
      
    padding-top: @pictextpic-section-pt;      
    padding-bottom: @pictextpic-section-pb;

    &.left {
      & .pictextpic__order_pic.left {
        order: 2;
        @media (min-width: 768px) and (max-width: 1023px) {

          order: 2;

        }
        @media (max-width: 767px) {
          order: unset;
        }
      }
      & .pictextpic__order_pic.right {
        order: 3;
        @media (min-width: 768px) and (max-width: 1023px) {

          order: 3;

        }
        @media (max-width: 767px) {
          order: unset;
        }
      }
      & .pictextpic__order_text {
        order: 1;
        @media (min-width: 768px) and (max-width: 1023px) {

          order: 1;

        }
        @media (max-width: 767px) {
          order: unset;
        }
      }
    }
    &.right {
      & .pictextpic__order_pic.left {
        order: 1;
        @media (min-width: 768px) and (max-width: 1023px) {

          order: 2;

        }
        @media (max-width: 767px) {
          order: unset;
        }
      }
      & .pictextpic__order_pic.right {
        order: 2;
        @media (min-width: 768px) and (max-width: 1023px) {

          order: 3;

        }
        @media (max-width: 767px) {
          order: unset;
        }
      }
      & .pictextpic__order_text {
        order: 3;
        @media (min-width: 768px) and (max-width: 1023px) {

          order: 1;

        }
        @media (max-width: 767px) {
          order: unset;
        }
      }
    }
    &.center {
      & .pictextpic__order_pic.left {
        order: 1;
        @media (min-width: 768px) and (max-width: 1023px) {

          order: 2;

        }
        @media (max-width: 767px) {
          order: unset;
        }
      }
      & .pictextpic__order_pic.right {
        order: 3;
        @media (min-width: 768px) and (max-width: 1023px) {

          order: 3;

        }
        @media (max-width: 767px) {
          order: unset;
        }
      }
      & .pictextpic__order_text {
        order: 2;
        @media (min-width: 768px) and (max-width: 1023px) {

          order: 1;

        }
        @media (max-width: 767px) {
          order: unset;
        }
      }
    }
  }
}