.request-form-simple {
  >.container {
    //max-width: 1276px;
  }
  .h1 {
    color: @text-color;
    font-weight: 700;
  }
}
.request-form-simple.full-section {
  >.container {
    max-width: 100%;
  }
}
.request-form-simple__wrapper {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
}
.request-form-simple-inner {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 50%;
  transition: all ease-in-out 0.5s;
  > img {
    display: block;
    height: auto;
    max-width: 75%;
    max-height: 75%;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
.request-form-simple-inner__wrapper {
  padding: 40px;
}
@media screen and (max-width: 1024px) {
  .request-form-simple-inner.visual {
    display: none;
  }
  .request-form-simple-inner {
    width: 100%;
    height: 472px;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
    transform: unset;
    transition: all ease-in-out 0.5s;
  }
}
@media screen and (max-width: 768px) {
  .request-form-simple-inner {
    width: 100%;
    height: auto;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
    transform: unset;
    padding: 20px 0;
  }
}
