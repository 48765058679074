@slider-fullimg-section-pt: 40px;
@slider-fullimg-section-pb: 40px;

@slider-fullimg-height: 450px;
@slider-fullimg-height-res: 250px;

@slider-fullimg-arrow: @primary-color;

@slider-fullimg-pt: 30px;
@slider-fullimg-pb: 30px;
@slider-fullimg-pl: 30px;
@slider-fullimg-pr: 30px;

@slider-fullimg-pt-res: 16px;
@slider-fullimg-pb-res: 16px;
@slider-fullimg-pl-res: 16px;
@slider-fullimg-pr-res: 16px;

.slider-full-img {

  &-section {

    padding-top: @slider-fullimg-section-pt;
    padding-bottom: @slider-fullimg-section-pt;

    overflow-x: hidden;

  }

  &__wrapper {

    position: relative;

    width: 100%;
    height: @slider-fullimg-height;

    @media (max-width: 1024px) {
      
      height: @slider-fullimg-height-res;

    }    

  }

  &__controls {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;

    width: 100%;

    padding-left: 45px;
    padding-right: 45px;

    z-index: 444;

    @media (max-width: 767px) {
      display: none;
    }

    &_inner {

      position: relative;

      height: 45px;

    }

    &_prev, &_next {

      position: absolute;
      top: 0;

      width: 25px;
      height: 45px;

      cursor: pointer;

      & svg {
        width: 25px;
        height: 45px;

        fill: @slider-fullimg-arrow;
      }

    }

    &_prev {
  
      left: 0;

      @media (max-width: 1024px) {
        left: -30px;
      }

    }

    &_next {
      
      right: 0;
      transform: rotate(180deg);

      @media (max-width: 1024px) {
        right: -30px;
      }

    }

  }

  &__inner {

    height: @slider-fullimg-height;

    @media (max-width: 1024px) {
      
      height: @slider-fullimg-height-res;

    }

    & .slick-dots {

      bottom: 20px;

      & button {

        position: relative;

        width: 22px;
        height: 22px;

        border: 2px solid #fff;

        &:before {

          content: '';
          
          width: 10px;
          height: 10px;
          background-color: transparent;

        }

      }

      & .slick-active button:before {

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        background-color: @primary-color;

      }

    }

  }

  &__item {

    height: @slider-fullimg-height;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media (max-width: 1024px) {
      
      height: @slider-fullimg-height-res;

    }

  }

}