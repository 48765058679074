.select2 {
  //# сам блок
  @select2-block-height:                      32px;
  @select2-block-background-color:            #ececec;
  @select2-block-border-color:                1px solid #aaa;
  @select2-block-border-radius:               1px;
  @select2-block-text-color:                  #646464;

  //# список
  @select2-optins-item-padding:               6px; // влияет на размер одного пункта option
  @select2-options-background-color:          @primary-color; // при наведении на пункт
  @select2-options-text-color:                #fff;

  @select2-options-selected-background-color: #ddd; // выбранный пункт
  @select2-options-selected-text-color:       #646464;


  display: block;
  width: 100%;
  height: @select2-block-height;
  border-radius: @select2-block-border-radius;
  box-shadow: inset 0 0 24px rgba(0,0,0,0.05);
  &:focus {
    outline: 0;
  }

  &-container .select2-selection--single {
    height: @select2-block-height;

    background-color: @select2-block-background-color;
    box-shadow: inset 0 0 24px rgba(0,0,0,0.05);
    border: @select2-block-border-color;
    border-radius: @select2-block-border-radius;
    &:focus {
      outline: 0;
    }
  }
  &-container--default .select2-selection--single .select2-selection__rendered {
    line-height: @select2-block-height;
    color: @select2-block-text-color;
  }
  &-container--default .select2-selection--single .select2-selection__arrow {
    height: @select2-block-height;
  }
  &-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: @select2-options-background-color;
    color: @select2-options-text-color;
  }
  &-container--default .select2-results__option[aria-selected=true] {
    background-color: @select2-options-selected-background-color;
    color: @select2-options-selected-text-color;
  }
  &-results__option {
    padding: @select2-optins-item-padding;
  }
}
