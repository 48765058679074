@text-section-pt: 40px;
@text-section-pb: 40px;

@text-section-pt-res: 20px;
@text-section-pb-res: 20px;

@text-pt: 30px;
@text-pb: 30px;
@text-pl: 30px;
@text-pr: 30px;

@text-pt-res: 16px;
@text-pb-res: 16px;
@text-pl-res: 16px;
@text-pr-res: 16px;

@text-bgc: #fff;

.text {

  &-section.regular {
    
    position: relative;

    padding-top: @text-section-pt;
    padding-bottom: @text-section-pb;

    @media (max-width: 767px) {

      padding-top: @text-section-pt-res;
      padding-bottom: @text-section-pb-res;

    }

    & .text__wrapper {

	    background-color: @text-bgc;

	    padding-top: @text-pt;
	    padding-bottom: @text-pb;
	    padding-left: @text-pl;
	    padding-right: @text-pr;

	    @media (max-width: 1199px) {
	      padding-top: @text-pt-res;
	      padding-bottom: @text-pb-res;
	      padding-left: @text-pl-res;
	      padding-right: @text-pr-res;
	    }

	}
    
  }

}