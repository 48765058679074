.special-offer__wrapper {
  background-color: #2d3e50;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}
.special-offer {
  .content {
    padding: 10px 0;
    .h2 {
      line-height: 42px;
      color: #fff;
      margin: 0;
    }
  }
  .btn {
    padding-bottom: 14px;
    border-width: 2px;
    &:active {
      padding-bottom: 14px;
      border-width: 2px;
    }
    &:hover {
      padding-bottom: 14px;
      border-width: 2px;
    }
  }
}
