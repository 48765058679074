.slider-portfolio__top.slider-portfolio__top--bg {
  padding-bottom: 10px;
  padding-top: 10px;
  background: #ffffff;
}

.slider-portfolio__top {
  padding-bottom: 20px;
}

.slider-portfolio__heading {
  margin-bottom: 0;
}

.slider-portfolio__arrows {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.slider-portfolio__arrows_item {
  cursor: pointer;

  svg {
    display: block;
    width: 38px;
    height: 38px;
    margin-left: 10px;
    fill: #0087c9;
    transition: all ease-in-out 0.2s;
    opacity: .5;

    &:hover {
      fill: #0087c9;
      opacity: 1;
    }
  }
}

.slider-portfolio__arrows_item.prev {
  transform: rotate(180deg);

  svg {
    margin-left: 0;
  }
}

.slider-portfolio__main {
  position: relative;
}

.slider-portfolio__item {
  position: relative;
  height: 240px;
  background-color: #fff; // << zamenit'
  margin-left: -1px;
  transition: border ease-in 0.1s, filter .3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //filter: grayscale(100%);
  text-decoration: none;
  &:hover {
    //filter: grayscale(0%);
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1px;
    width: 1px;
    height: 150px;
    background-color: #ebebeb; // << zamenit'
  }

  & .pb {
    font-weight: 700;
    font-size: 24px;
    color: #F80024;
  }
  & .flatseller {
    & span {
      font-size: 24px;
      font-weight: 700;
    }
    & span:nth-child(1){color:#4d7280;}
    & span:nth-child(2){color:#0075a7;}
    & span:nth-child(3){color:#000000;}
  }

  & .pb {

  }

  img {
    display: block;
    max-width: 80%;
    max-height: 80%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    display: block;
    max-width: 80%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  & .rounded {
    border-radius: 50px;
  }
}

.slider-portfolio__item_wrapper {
  position: relative;
  left: 1px;
}
