
.spoiler {
  @spoiler-title-normal-color:                 @link-color;
  @spoiler-title-active-color:                 @link-hover;
  @spoiler-title-hover-color:                  @link-hover;

  @spoiler-title-font-weight:                  400;
  @spoiler-title-font-size:                    18px;
  @spoiler-title-border-width:                 1px;
  @spoiler-title-border-type:                  dashed;
  @spoiler-title-border-color:                 @link-color;

  margin-top: 0;
  margin-bottom: 20px;

  &.active {
    & .spoiler__text {
      display: block;
    }
    & .spoiler__title {
      color: @spoiler-title-active-color;
      border-bottom-color: @spoiler-title-active-color;
    }
    & .icon {
      fill: @spoiler-title-active-color;
      transform: rotate(180deg);
    }
  }

  &__text {
    display: none;
    padding-top: 20px;
  }

  &__title {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-right: 25px;
    color: @spoiler-title-normal-color;
    font-weight: @spoiler-title-font-weight;
    font-size: @spoiler-title-font-size;
    border-bottom-width: @spoiler-title-border-width;
    border-bottom-style: @spoiler-title-border-type;
    border-bottom-color: @spoiler-title-border-color;

    cursor: pointer;
  }

  &-title-icon-wrapper {
    position: absolute;
    top: 3px;
    right: -25px;
    width: 14px;
    height: 14px;
    fill: @link-color;

    & .icon {
      width: 14px;
      height: 14px;

      transition: 0.15s transform ease;
    }
  }

  &__title:hover  {
    color: @spoiler-title-hover-color;
    border-bottom-color: @spoiler-title-hover-color;

  & .icon {
      fill: @spoiler-title-hover-color;
    }
  }
}
