.features__item {
  position: relative;
  height: 100%;
  background-color: #fff;
  border-radius: 2px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  box-shadow: none;
  transition: box-shadow 200ms ease;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 45px;
  padding-right: 45px;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1px;
    width: 1px;
    height: 150px;
    background-color: #dfdfdf;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.features__item_wrapper {
  &:last-child {
    .features__item {
      &:after {
        background-color: transparent;
      }
    }
  }
}
.features__item_visual {
  height: auto;
  margin-right: 20px;
  img {
    width: 100px;
    height: auto;
  }
}
.features__item_number {
  margin-right: 20px;
  font-size: 32px;
}
.features__item_text {
  .lead {
    font-size: 24px;
  }
  .addition {
    color: #424141;
  }
}
body.safari {
  .features__item {
    display: block;
  }
}
@media (max-width: 1024px) {
  .features__item {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1023px) {
  .features__item_wrapper {
    &:nth-child(even) {
      .features__item {
        &:after {
          background-color: transparent;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .features__item_wrapper {
    .features__item {
      &:after {
        background-color: transparent;
      }
    }
  }
}
